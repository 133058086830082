import React, { Component } from "react";
import {
  goBack,
  executePost,
  executeGet,
  formatTanggal,
} from "../functions/Kubuku";
import { TITIK_BACA, TITIK_BACA_BERITA, TITIK_BACA_GET_RSS } from "../api";
import Glider from "react-glider";
import "glider-js/glider.min.css";

import ModalLoading from "../components/ModalLoading";

import { useParams, useNavigate } from "react-router-dom";

function WithNavigate(props) {
  let navigate = useNavigate();
  return <NewsViewer {...props} navigate={navigate} params={useParams()} />;
}

class NewsViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      data: "",
      alias: this.props.params.alias,
      lokasi: "",
      lat: "-7.557130246009252",
      long: "110.79448368501073",
      alt: "1",
      flagData: false,
      color: window.localStorage.getItem("color"),
      darkcolor: "bg-orang-dark",
      textcolor: "text-orange",
      imgHead: "",
      imgSplash: "",
    };
  }

  componentDidMount = async () => {
    this.getTheme();
    this.getDetailBerita();
  };

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem("themes") != null) {
        const ljson = window.localStorage.getItem("themes");
        const json = JSON.parse(ljson);
        if (json.code == 200) {
          if (json.warna != "") {
            this.setState({
              color: json.warna,
            });
          }
          if (json.dark != "") {
            this.setState({
              darkcolor: json.dark,
            });
          }
          if (json.text != "") {
            this.setState({
              textcolor: json.text,
            });
          }
          if (json.head != "") {
            this.setState({
              imgHead: json.head,
            });
          }
          if (json.splash != "") {
            this.setState({
              imgSplash: json.splash,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  getDetailBerita = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const json = await executeGet(
        TITIK_BACA_GET_RSS + "/bacakominfo/" + this.state.params.id
      ); //, formData);
      this.setState({
        data: json,
        flagData: true,
        isLoading: false,
      });
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div
            onClick={() => goBack()}
            className={
              "flex p-4 " +
              this.state.color +
              " text-lg text-white font-semibold items-center"
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-8"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="white"
                strokeWidth="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Detail Berita
          </div>
        </nav>
        {this.state.isLoading && <ModalLoading />}
        {this.state.flagData && (
          <>
            <div className="mt-20 px-4 mb-20">
              <h1 className="text-xl font-semibold text-black line-clamp-3">
                {this.state.data.title}
              </h1>
              <div className="mt-4 text-xs font-medium">
                {this.state.data.publisher}
              </div>
              <div className="text-gray-light text-xs">
                {formatTanggal(atob(this.state.params.tanggal))}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.data.image }}
              />
              <div className="text-sm text-black">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.data.berita }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default WithNavigate;
