import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner1 from "../assets/images/banner-1.jpg";
import banner2 from "../assets/images/banner-2.jpg";
import banner3 from "../assets/images/banner-3.jpg";

class LoadingScreen extends Component {
  render() {
    // Slider settings
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    return (
      <div className="w-full h-full">
        <div class="flex flex-col h-screen bg-no-repeat bg-cover bg-center bg-[url('/src/assets/images/bg-main.png')]">
          <div class="m-auto w-full">
            <Slider {...settings}>
              <div>
                <div>
                  <img src={banner1} alt="gedung perpusnas ri" class="w-full" />
                </div>
              </div>
              <div>
                <div>
                  <img src={banner2} alt="ruang baca anak" class="w-full" />
                </div>
              </div>
              <div>
                <div>
                  <img src={banner3} alt="working space" class="w-full" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
export default LoadingScreen;
