import React, { Component } from 'react'
import {TITIK_BACA_CONTENT_CATEGORIES, TITIK_BACA, TITIK_BACA_TOP, TITIK_BACA_REKOMENDASI, TITIK_BACA_TERLARIS } from '../api'
import { executeGet, executePost, formatTanggal, goBack } from '../functions/Kubuku'
import LazyLoad from 'react-lazy-load';

import SkeletonSearch from '../components/SkeletonSearch'
import { useParams, useNavigate } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import imgNoBook from "../assets/images/es-nocontent.svg";


function WithNavigate(props) {
  let navigate = useNavigate()
  return <ContentCreator {...props} navigate={navigate} params={useParams()} />
}

class ContentCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      dataBerita: '',
      alias: window.localStorage.getItem('alias'),
      lokasi: '',
      jauh: false,
      msgJauh: '',
      lokasiMsgJauh: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      flagData: false,
      dataTop: [],
      hal: 1,
      mode: 1,
      search: '',
      flagNoData: false,
      flagNoBook: false,
      msgTitleNoData: 'Buku Tidak Ditemukan',
      msgNoData: 'Buku yang anda cari tidak ditemukan, silahkan cari buku lain',
      flagMoreSearch: false,
      color: window.localStorage.getItem('color'),
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: '',
      firstRun: true
    }
  }

  componentDidMount = async () => {
    if (this.state.firstRun) {
      this.setState({
        alias: window.localStorage.getItem('alias'),
        firstRun: false
      })
      this.getTheme();
      //this.getLocation()
      this.loadData(this.state.lat, this.state.long, this.state.alt)
    }
  }

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let long = Number(position.coords.longitude)
      let lat = Number(position.coords.latitude);
      let alt = position.coords.altitude;
      this.setState({
        lat: lat,
        long: long,
        alt: alt
      })
      this.loadData(lat, long, alt)
    })
  }
  
  loadData = async (lat, long, alt) => {

    this.getBukuPopuler(lat, long, alt)

  }

  
  getBukuPopuler = async (lat, long, alt) => {
    try {
      let hal = parseInt(this.state.hal)
      let ldata = this.state.dataTop
      let alias = window.localStorage.getItem('alias')
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', hal)
      //let json = await executePost(TITIK_BACA + alias, formData)
      let json = {
        "code": 200,
        "data": [
            {
                "id": "1",
                "judul": "Permainan Bahasa Inggris Sederhana",
                "penerbit": "Putar dan tebak buah apa dalam bahasa inggris",
                "penulis": "Permainan Sederhana",
                "isbn": "-",
                "eisbn": "",
                "sinopsis": "",
                "cover_file": "https:\/\/bintangpusnas.perpusnas.go.id\/images\/fruit.png",
                "qty": "50"
            },
            {
              "id": "2",
              "judul": "Permainan Matematika Sederhana",
              "penerbit": "Hitung-hitungan matematika sederhana",
              "penulis": "Perkalian, pembagian, penjumlahan, pengurangan",
              "isbn": "-",
              "eisbn": "",
              "sinopsis": "",
              "cover_file": "https:\/\/bintangpusnas.perpusnas.go.id\/images\/mathgenius.png",
              "qty": "50"
          }
        ]
      }; 
      if (json.code == 200) {
        this.setState({
          dataTop: json.data,
          alias: json.token,
          isLoading: false,
          flagData: true
        })
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  gotoDetail = async (id) => {
    window.location.href = '/games/'+ id
  }
  gotoHome = () => {
    goBack();
    //window.location.href = window.location.origin + '/home' + '/' + this.state.alias
  }
  render() {
    return (
      <div>
        {this.state.isLoading && <SkeletonSearch />}
        <div className={"mb-16"}>
          
          <iframe src={"https://bintangpusnas.perpusnas.go.id/publikasi/?nobar=1"} style={{position:"absolute", top:"0px", left:"0px", width:"100vw", height:"100vh"}}></iframe>
          
        </div>
      </div>
    )
  }
}

export default WithNavigate
