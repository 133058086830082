import { Component } from 'react'
import { clientSettings, executeGet, executePost } from '../functions/Kubuku'
import loaderGif from '../assets/images/loader.gif'

class ModalLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: ''
    }
  }

  componentDidMount = async () => {}

  render() {
    return (
      <div>
        <div className="hidden lg:block">
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[20%] h-[17%] mx-auto">
              {/*content*/}
              <div className="flex justify-center items-center h-screen">
                <div className="p-4 border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none m-auto">
                  <div className="justify-center items-center">
                    <img src={loaderGif} alt="loader" className="h-10 mx-auto" />
                  </div>
                  <div className="text-center mt-4 font-semiboldtext-black">Tunggu Sebentar...</div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>

        <div className="block lg:hidden">
          <div className="flex h-screen overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="w-[60%] h-[120px] mx-auto">
              {/*content*/}
              <div className="flex justify-center items-center h-screen">
                <div className="p-4 border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none m-auto">
                  <div className="justify-center items-center">
                    <img src={loaderGif} alt="loader" className="h-10 mx-auto" />
                  </div>
                  <div className="text-center mt-4 font-semibold text-black">Tunggu Sebentar...</div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      </div>
    )
  }
}

export default ModalLoading
