import { Component } from "react";

class SkeletonSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
  }

  componentDidMount = async () => {};

  render() {
    return (
      <div>
        <div className="px-4">
          <div className="mt-4 columns-3 gap-2">
            <div className="satu break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[140px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
            <div className="dua break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[120px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
            <div className="tiga break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[120px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
            <div className="empat break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[140px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
            <div className="lima break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[140px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
            <div className="enam break-inside-avoid rounded-xl mb-2 shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
              <div className="h-[130px] overflow-hidden animate-pulse">
                <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
              </div>
              <div className="p-2 min-h-[140px] animate-pulse">
                <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 mt-3 bg-gray-300 rounded-full"></div>
                <div className="w-16 h-3 mt-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SkeletonSearch;
