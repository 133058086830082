import React, { Component } from "react";
import { goBack, executePost, executeGet } from "../functions/Kubuku";
import { BASE_URL, TITIK_BACA, TITIK_BACA_CEKLOKASI } from "../api";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import SkeletonBookDetail from "../components/SkeletonBookDetail";
import QRCode from "react-qr-code";
import logoLiterasiKeluarga from "../assets/images/logo_literasikeluarga.png";

import { useParams, useNavigate } from "react-router-dom";
import image_location from "../assets/images/location.png";
import bgBottom from "../assets/images/bg-bottom.svg";

function WithNavigate(props) {
  let navigate = useNavigate();
  return <InputKTP {...props} navigate={navigate} params={useParams()} />;
}

class InputKTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isModal: false, //popup loading
      showMain: false,
      params: this.props.params,
      data: "",
      datar: "",
      dataRekomendasi: "",
      dataPenyumbang: "",
      dataterdekat: "",
      alias: this.props.params.alias, //window.localStorage.getItem('alias'), //this.props.params.alias,
      lokasi: "",
      lat: "-7.557130246009252",
      long: "110.79448368501073",
      alt: "1",
      flagData: false,
      isError: false,
      errorMessage: "",
      msgJauh:
        "Maaf saat ini anda sudah diluar jangkauan, silahkan pilih titik baca di sekitar anda.",
      clamp: "line-clamp-6",
      more: "Selengkapnya",
      urlbuku: "",
      hdr: "",
      color: window.localStorage.getItem("color"),
      darkcolor: "bg-orang-dark",
      textcolor: "text-orange",
      imgHead: "",
      imgSplash: "",
      nomoKTP: "",
      pranikah: false,
      menikah: false,
      goldenage: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.params.tipe == "5") {
      this.setState({ pranikah: true });
    } else if (this.props.params.tipe == "6") {
      this.setState({ menikah: true });
    } else if (this.props.params.tipe == "7") {
      this.setState({ goldenage: true });
    }

    let nomorKTP = window.localStorage.getItem("ktp");
    if (nomorKTP != null) {
      var umurValid = true;
      const batasUmur = 18;
      const currYear = Number(new Date().getFullYear().toString().substr(-2));
      const batasLahir = currYear - batasUmur;

      let tahun = nomorKTP.substr(10, 2);
      if (Number(tahun) < currYear) {
        //ini kelahiran 2023 s/d 2000
        if (Number(tahun) > batasLahir) {
          umurValid = false;
          this.setState({
            isError: true,
            errorMessage:
              "Maaf anda belum diperbolehkan untuk mengakses koleksi ini.",
          });
        }
      }
      if (umurValid == true) {
        window.location.href = window.location.href =
          window.location.origin + "/home" + "/" + this.props.params.alias;
      }
    }
    this.setState({
      alias: this.props.params.alias, //window.localStorage.getItem('alias')
    });
    //this.getDetailBuku()
    //this.getTheme();
  };

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem("themes") != null) {
        const ljson = window.localStorage.getItem("themes");
        const json = JSON.parse(ljson);
        if (json.code == 200) {
          if (json.warna != "") {
            this.setState({
              color: json.warna,
            });
          }
          if (json.dark != "") {
            this.setState({
              darkcolor: json.dark,
            });
          }
          if (json.text != "") {
            this.setState({
              textcolor: json.text,
            });
          }
          if (json.head != "") {
            this.setState({
              imgHead: json.head,
            });
          }
          if (json.splash != "") {
            this.setState({
              imgSplash: json.splash,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  gotoDetail = async (id) => {
    window.location.href = "/detailBuku/" + id + "/" + this.state.alias;
  };

  gotoHome = () => {
    window.location.href =
      window.location.origin + "/home" + "/" + this.state.alias;
  };
  handleChangeKTP = (text) => {
    this.setState({ nomoKTP: text });
  };

  validasiKTP = async () => {
    let nomorKTP = this.state.nomoKTP;
    if (!isNaN(+nomorKTP)) {
      if (nomorKTP.length != 16) {
        this.setState({
          isError: true,
          errorMessage: "Nomor KTP tidak valid! Mohon dicek kemabali.",
        });
      } else {
        //3576014403910003
        var umurValid = true;
        const batasUmur = 18;
        const currYear = Number(new Date().getFullYear().toString().substr(-2));
        const batasLahir = currYear - batasUmur;

        let tahun = nomorKTP.substr(10, 2);
        if (Number(tahun) < currYear) {
          //ini kelahiran 2023 s/d 2000
          if (Number(tahun) > batasLahir) {
            umurValid = false;
            this.setState({
              isError: true,
              errorMessage:
                "Maaf anda belum diperbolehkan untuk mengakses koleksi ini.",
            });
          }
        }
        if (umurValid == true) {
          window.localStorage.setItem("ktp", nomorKTP);
          this.props.navigate(
            "/listBuku/" + this.props.params.tipe + "/" + this.state.alias
          );
        }
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "Nomor KTP tidak valid! Mohon dicek kemabali.",
      });
    }
  };

  render() {
    return (
      <>
        <div>
          <div className="top-0">
            {this.state.pranikah && (
              <div className="relative mb-8 overflow-hidden p-4 h-52 sm:h-60 lg:h-80 bg-gradient-to-r from-[#9D50BB] to-[#6E48AA] text-white">
                <div
                  onClick={() => this.gotoHome()}
                  className={
                    "flex p-4  text-lg text-white font-semibold items-center"
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-8"
                  >
                    <path
                      d="M15 6L9 12L15 18"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="rounded-full h-36 w-36 lg:h-60 lg:w-60 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div className="rounded-full h-40 w-40 lg:h-64 lg:w-64 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div className="rounded-full h-44 w-44 lg:h-[17rem] lg:w-[17rem] bg-white bg-opacity-20 absolute bottom-[-44px] right-[-44px] lg:bottom-0 lg:right-0"></div>
                <div
                  style={{
                    backgroundImage: "url('" + bgBottom + " ')",
                    width: "101vw",
                    left: "-1px",
                  }}
                  class={
                    "absolute bottom-0 left-0 bg-no-repeat bg-cover h-16 sm:h-24 lg:h-40 w-full z-50"
                  }
                ></div>
                <h1 className="mt-4 font-bold text-3xl">Pra Nikah</h1>
              </div>
            )}
            {this.state.menikah && (
              <div class="relative overflow-hidden mb-8 p-4 h-52 sm:h-60 lg:h-80 bg-gradient-to-r from-[#00c6ff] to-[#0072ff] text-white">
                <div
                  onClick={() => this.gotoHome()}
                  className={
                    "flex p-4  text-lg text-white font-semibold items-center"
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-8"
                  >
                    <path
                      d="M15 6L9 12L15 18"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div class="rounded-full h-36 w-36 lg:h-60 lg:w-60 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div class="rounded-full h-40 w-40 lg:h-64 lg:w-64 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div class="rounded-full h-44 w-44 lg:h-[17rem] lg:w-[17rem] bg-white bg-opacity-20 absolute bottom-[-44px] right-[-44px] lg:bottom-0 lg:right-0"></div>
                <div
                  style={{
                    backgroundImage: "url('" + bgBottom + " ')",
                    width: "101vw",
                    left: "-1px",
                  }}
                  class="absolute bottom-0 left-0 bg-no-repeat bg-cover h-16 sm:h-24 lg:h-40 w-full z-20"
                ></div>
                <h1 class="mt-4 font-bold text-3xl">Menikah</h1>
              </div>
            )}
            {this.state.goldenage && (
              <div class="relative overflow-hidden mb-8 p-4 h-52 sm:h-60 lg:h-80 bg-gradient-to-r from-[#FDC830] to-[#F37335] text-white">
                <div
                  onClick={() => this.gotoHome()}
                  className={
                    "flex p-4  text-lg text-white font-semibold items-center"
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-8"
                  >
                    <path
                      d="M15 6L9 12L15 18"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div class="rounded-full h-36 w-36 lg:h-60 lg:w-60 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div class="rounded-full h-40 w-40 lg:h-64 lg:w-64 bg-white bg-opacity-10 absolute bottom-[-40px] right-[-40px] lg:bottom-0 lg:right-0"></div>
                <div class="rounded-full h-44 w-44 lg:h-[17rem] lg:w-[17rem] bg-white bg-opacity-20 absolute bottom-[-44px] right-[-44px] lg:bottom-0 lg:right-0"></div>
                <div
                  style={{
                    backgroundImage: "url('" + bgBottom + " ')",
                    width: "101vw",
                    left: "-1px",
                  }}
                  class="absolute bottom-0 left-0 bg-no-repeat bg-cover h-16 sm:h-24 lg:h-40 w-full z-20"
                ></div>
                <h1 class="mt-4 font-bold text-3xl">Gold Age</h1>
              </div>
            )}
          </div>
          <div>
            <div className="ml-8 mr-8 mb-2">
              Untuk mengakses koleksi ini, anda diwajibkan telah memiliki KTP
              dan telah berusia minimal 18 tahun.
            </div>
            <div className="ml-8 mr-8 mb-2">Input KTP</div>
            <div className="flex items-center ml-8 mr-8">
              <div className="flex-1">
                <div className="relative">
                  <input
                    type="text"
                    onChange={(event) => {
                      this.handleChangeKTP(event.target.value);
                    }}
                    className="flex  w-full pr-4 pl-2 py-2.5 bg-white border border-gray-200 rounded-lg placeholder:text-gray-400 text-sm focus:outline-none focus:border-orange focus:ring-orange"
                    placeholder="Masukan Nomor KTP"
                  />
                </div>
              </div>
            </div>
            <div className="ml-8 mr-8 mb-2 mt-2">
              <button
                onClick={() => {
                  this.validasiKTP();
                }}
                className="pl-8 pr-8 pt-2 pb-2 bg-white border border-gray-400 rounded-lg"
              >
                Kirim
              </button>
            </div>
          </div>
          {this.state.isError && (
            <div className="px-4">
              <div
                className={
                  "relative mb-2.5 p-4 bg-red text-white rounded-xl shadow-md overflow-hidden"
                }
              >
                <div className="w-52 sm:w-96 md:w-auto">
                  <p className="mb-3 text-sm font-semibold">
                    {this.state.errorMessage}
                  </p>
                  <a
                    type="button"
                    onClick={() => {
                      this.setState({ isError: false });
                    }}
                    className="py-1 px-6 border border-white text-sm font-semibold rounded-full"
                  >
                    OK
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default WithNavigate;
