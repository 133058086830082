import React, { useEffect, useRef } from "react";
import KioskBoard from "kioskboard";
import { executePost, goBack } from "../functions/Kubuku";
import Swal from "sweetalert2";

const Settings = () => {
  const passwordRef = useRef("");
  const keyboardRef = useRef(null);

  //NOTE - KEYBOARD VIRTUAL
  useEffect(() => {
    if (keyboardRef.current) {
      KioskBoard.run(keyboardRef.current, {
        language: "en",
        theme: "light",
        keysArrayOfObjects: objectKeyboard,
        capsLockActive: false,
      });

      // Event listener untuk menangkap perubahan dari KioskBoard
      keyboardRef.current.addEventListener("change", (e) => {
        passwordRef.current = e.target.value;
      });
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // NOTE Lakukan validasi dan pengiriman data di sini
    const formData = new FormData();
    formData.append("password", passwordRef.current);

    const response = await executePost(
      "http://127.0.0.1:81/api/titikBaca/settings",
      formData
    );
    if (response.code == 404) {
      Swal.fire({
        title: response.msg,
        icon: "error",
      });
    } else if (response.code == 200) {
      Swal.fire({
        title: response.msg,
        icon: "success",
      });
      if (window.electron && window.electron.sendMessage) {
        window.electron.sendMessage("MENU", { dummy: 0 });
      } else {
        console.error("window.electron or sendMessage is undefined");
      }
    }
  };

  return (
    <div>
      <nav className="fixed top-0 w-full z-10 bg-orange shadow-md ">
        <div
          onClick={() => goBack()}
          className="flex p-4 text-lg text-white font-semibold items-center"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-8"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Setting
        </div>
      </nav>

      <div className="max-w-md mx-10 sm:mx-auto mt-96">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              ref={keyboardRef}
              id="password"
              type="password"
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-orange text-white font-semibold py-2 rounded-md hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

const objectKeyboard = [
  {
    0: "Q",
    1: "W",
    2: "E",
    3: "R",
    4: "T",
    5: "Y",
    6: "U",
    7: "I",
    8: "O",
    9: "P",
  },
  {
    0: "A",
    1: "S",
    2: "D",
    3: "F",
    4: "G",
    5: "H",
    6: "J",
    7: "K",
    8: "L",
  },
  {
    0: "Z",
    1: "X",
    2: "C",
    3: "V",
    4: "B",
    5: "N",
    6: "M",
  },
];

export default Settings;
