import { Component } from 'react'

class SkeletonGPSLocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      floatMenu: false,
      width: 0,
      height: 0,
      flagPotraitPhone: false
    }
  }

  componentDidMount = async () => {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    // console.log('innerWidth = ' + window.innerWidth)
    // console.log('innerHeight = ' + window.innerHeight)
    if (window.innerWidth < 500 && window.innerHeight > 650) {
      // console.log("disini")
      this.setState({ flagPotraitPhone: true })
    } else if (window.innerWidth > 700 && window.innerHeight > 500) {
      // console.log("disini 1")
      this.setState({ flagPotraitPhone: true })
    } else {
      // console.log("disini 2")
      this.setState({ flagPotraitPhone: false })
    }
    // console.log('innerWidth = ' + this.state.width)
    // console.log('innerHeight = ' + this.state.height)
  }

  render() {
    return (
      <div>
        <div className="fixed top-0 w-full h-[600px] bg-gray-300 animate-pulse"></div>
        {/* <!-- Main Content Wrap --> */}
        <div className="relative mt-[530px] sm:mt-[30%] md:mt-[40%] bg-white z-1 before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full">
          {/* <!-- Your Location --> */}
          <div className="flex mt-3 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-28 h-3 bg-gray-300 rounded-full"></div>
              <div className="w-40 h-3 mt-2 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Your Location --> */}
          {/* <!-- Titel Section --> */}
          <div className="px-4 items-center mt-4 mb-3 animate-pulse">
            <div className="w-36 h-4 bg-gray-300 rounded-full"></div>
          </div>
          {/* <!-- End Title Section --> */}
          {/* <!-- Location List Item --> */}
          <div className="flex mb-4 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Location List Item --> */}
          {/* <!-- Location List Item --> */}
          <div className="flex mb-4 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Location List Item --> */}
          {/* <!-- Location List Item --> */}
          <div className="flex mb-4 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Location List Item --> */}
          {/* <!-- Location List Item --> */}
          <div className="flex mb-4 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Location List Item --> */}
          {/* <!-- Location List Item --> */}
          <div className="flex mb-4 px-4 items-center animate-pulse">
            <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
            <div>
              <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
          {/* <!-- End Location List Item --> */}
        </div>
        {/* <!-- End Main Content Wrap --> */}

        {/* <!-- Bottom Menu --> */}
        {this.state.flagPotraitPhone && (
          <div className="fixed bottom-0 w-full bg-white rounded-t-2xl py-3 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] z-50">
            <div className="grid grid-cols-5">
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center animate-pulse">
                <center>
                  <div className="flex flex-col relative">
                    <div className="w-[52px] h-[52px] bg-gray-300 rounded-full drop-shadow-md p-3 items-center absolute top-[-35px] left-[-1px]"></div>
                    <div className="w-12 h-3 mt-[29px] bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        )}
        {/* <!-- End Bottom Menu --> */}
        <div className={this.state.width > '650' && this.state.height < '500' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg block'}></div>
        <div className={this.state.width < '500' && this.state.height > '650' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg hidden'}></div>
      </div>
    )
  }
}

export default SkeletonGPSLocation
