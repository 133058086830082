import { Component } from 'react';
import logo from '../assets/opening.gif';

class Loader extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render(){
    return (
      <div className="mx-auto flex dark:bg-white">
        <div className="flex items-center justify-center h-screen w-screen">
            <img className="h-6/12 mx-auto" src={logo} alt="loading..." />
            
        </div>
        
      </div>
      
    );

  }
 
}

export default Loader;
