import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pages from "./pages";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import { Component } from "react";
import GoogleOneTapLogin from "react-google-one-tap-login";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      flagGoogle: false,
    };
    //this.konfigurasi();
    //alert("apps 1");
  }
  shouldComponentUpdate = async () => {
    //alert("Apps 3");
  };
  componentDidMount = async () => {
    /*setTimeout(() => {
       this.setState({ isLoading: false});
    }, 5000)
    */

    this.setState({ isLoading: false });
    // window.localStorage.setItem("ktp", "0000000000000000");
    if (localStorage.getItem("name") == null) {
      this.setState({ flagGoogle: true });
    }
  };

  hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  render() {
    return this.state.isLoading ? (
      <Loader />
    ) : (
      <BrowserRouter>
        {this.state.flagGoogle && (
          <GoogleOneTapLogin
            onError={(error) => console.log(error)}
            onSuccess={(response) => {
              localStorage.setItem("email", response.email);
              localStorage.setItem("name", response.name);
            }}
            googleAccountConfigs={{
              client_id:
                "301415234328-98f5ceieu7b9j60g62usjculjcb4tec2.apps.googleusercontent.com",
            }}
          />
        )}

        <Pages />
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
