import React, { Component } from "react";
import {
  goBack,
  executePost,
  executeGet,
  formatTanggal,
} from "../functions/Kubuku";
import { TITIK_BACA, TITIK_BACA_BERITA } from "../api";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import parse from "html-react-parser";

import ModalLoading from "../components/ModalLoading";

import { useParams, useNavigate } from "react-router-dom";

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GamesPage {...props} navigate={navigate} params={useParams()} />;
}

class GamesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      id: this.props.params.id,
      data: "",
      alias: this.props.params.alias,
      lokasi: "",
      flagData: false,
      flagBerita: false,
      urlGames: "",
      color: window.localStorage.getItem("color"),
    };
  }

  componentDidMount = () => {
    //this.getRSS();
    if (this.state.id == "1") {
      this.setState({
        urlGames: "https://perpusnas.titikbaca.id/fruit/index.html",
      });
    } else if (this.state.id == "2") {
      this.setState({
        urlGames: "https://perpusnas.titikbaca.id/mathgenius/index.html",
      });
    } else {
      this.setState({ urlGames: "https://titikbaca.id" });
    }
  };

  getRSS = async () => {
    try {
      let json = await executeGet(
        "https://kubuku.id/api/titikBaca/downloadRSSDetail/" +
          this.props.params.id
      );
      this.setState({
        news: json,
        flagBerita: true,
      });
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div
            onClick={() => goBack()}
            className={
              "flex p-4 " +
              this.state.color +
              " text-lg text-white font-semibold items-center"
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-8"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="white"
                strokeWidth="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Permainan Edukasi
          </div>
        </nav>
        <div className="mt-10" style={{ height: "calc(100vh - 10px" }}>
          <iframe src={this.state.urlGames} className="w-full h-full"></iframe>
        </div>
      </div>
    );
  }
}

export default WithNavigate;
