import { Component } from 'react'

class SkeletonNews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      floatMenu: false,
      width: 0,
      height: 0,
      flagPotraitPhone: false
    }
  }

  componentDidMount = async () => {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    // console.log('innerWidth = ' + window.innerWidth)
    // console.log('innerHeight = ' + window.innerHeight)
    if (window.innerWidth < 500 && window.innerHeight > 650) {
      // console.log("disini")
      this.setState({ flagPotraitPhone: true })
    } else if (window.innerWidth > 700 && window.innerHeight > 500) {
      // console.log("disini 1")
      this.setState({ flagPotraitPhone: true })
    } else {
      // console.log("disini 2")
      this.setState({ flagPotraitPhone: false })
    }
    // console.log('innerWidth = ' + this.state.width)
    // console.log('innerHeight = ' + this.state.height)
  }

  render() {
    return (
      <div>
        <nav className="p-4 fixed flex w-full top-0 z-10 bg-gray-300 shadow-md">
          <div className="text-lg text-white font-semibold items-center">Berita</div>
        </nav>
        <div className="px-4 pt-16 mb-24">
          {/* <!-- Titel Section --> */}
          <div className="mt-4 mb-2 animate-pulse">
            <div className="w-32 h-4 bg-gray-300 rounded-full"></div>
          </div>
          {/* <!-- End Title Section --> */}
          <div className="relative w-full h-52 bg-gray-300 rounded-2xl">
            <div className="w-full max-h-32 p-4 animate-pulse absolute bottom-0 left-0">
              <div className="w-72 h-4 bg-gray-100 rounded-full"></div>
              <div className="w-28 h-4 mt-3 bg-gray-100 rounded-full"></div>
              <div className="flex items-center mt-2">
                <div className="w-8 h-8 mr-2 bg-gray-100 rounded-full"></div>
                <div>
                  <div className="w-20 h-2 bg-gray-100 rounded-full"></div>
                  <div className="w-24 h-2 mt-2 bg-gray-100 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
          <ul className="flex my-4 animate-pulse" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
            <li role="presentation">
              <div className="w-24 h-9 mr-2 inline-block py-2 px-6 bg-gray-300 text-white text-sm font-semibold rounded-full"></div>
            </li>
            <li role="presentation">
              <div className="w-24 h-9 mr-2 inline-block py-2 px-6 bg-gray-300 text-white text-sm font-semibold rounded-full"></div>
            </li>
            <li role="presentation">
              <div className="w-24 h-9 mr-2 inline-block py-2 px-6 bg-gray-300 text-white text-sm font-semibold rounded-full"></div>
            </li>
          </ul>
          <div id="myTabContent">
            <div id="local" role="tabpanel" aria-labelledby="local-tab">
              <div className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md">
                <div className="w-[90px] h-[90px] mr-3 bg-gray-300 rounded-xl animate-pulse"></div>
                <div className="w-8/12 animate-pulse">
                  <div className="w-52 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-40 h-4 bg-gray-300 rounded-full"></div>
                  <div className="mt-3 flex items-center">
                    <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                    <div className="ml-2 text-white text-xs">
                      <div className="w-24 h-3 mb-1 bg-gray-300 rounded-full"></div>
                      <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md">
                <div className="w-[90px] h-[90px] mr-3 bg-gray-300 rounded-xl animate-pulse"></div>
                <div className="w-8/12 animate-pulse">
                  <div className="w-52 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-40 h-4 bg-gray-300 rounded-full"></div>
                  <div className="mt-3 flex items-center">
                    <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                    <div className="ml-2 text-white text-xs">
                      <div className="w-24 h-3 mb-1 bg-gray-300 rounded-full"></div>
                      <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md">
                <div className="w-[90px] h-[90px] mr-3 bg-gray-300 rounded-xl animate-pulse"></div>
                <div className="w-8/12 animate-pulse">
                  <div className="w-52 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-40 h-4 bg-gray-300 rounded-full"></div>
                  <div className="mt-3 flex items-center">
                    <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                    <div className="ml-2 text-white text-xs">
                      <div className="w-24 h-3 mb-1 bg-gray-300 rounded-full"></div>
                      <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden" id="sekitar" role="tabpanel" aria-labelledby="global-tab">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.
              </p>
            </div>
            <div className="hidden" id="nasional" role="tabpanel" aria-labelledby="global-tab">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.
              </p>
            </div>
            <div className="mt-4 w-48 h-10 bg-gray-300 rounded-full mx-auto animate-pulse"></div>
          </div>
        </div>

        {/* <!-- Bottom Menu --> */}
        {this.state.flagPotraitPhone && (
          <div className="fixed bottom-0 w-full bg-white rounded-t-2xl py-3 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] z-50">
            <div className="grid grid-cols-5">
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center animate-pulse">
                <center>
                  <div className="flex flex-col relative">
                    <div className="w-[52px] h-[52px] bg-gray-300 rounded-full drop-shadow-md p-3 items-center absolute top-[-35px] left-[-1px]"></div>
                    <div className="w-12 h-3 mt-[29px] bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        )}
        {/* <!-- End Bottom Menu --> */}
        <div className={this.state.width > '650' && this.state.height < '500' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg block'}></div>
        <div className={this.state.width < '500' && this.state.height > '650' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg hidden'}></div>
      </div>
    )
  }
}

export default SkeletonNews
