import React, { Component } from 'react'
import {TITIK_BACA_CONTENT_CATEGORIES, TITIK_BACA, TITIK_BACA_TOP, TITIK_BACA_REKOMENDASI, TITIK_BACA_TERLARIS } from '../api'
import { executeGet, executePost, formatTanggal, goBack } from '../functions/Kubuku'
import LazyLoad from 'react-lazy-load';

import SkeletonSearch from '../components/SkeletonSearch'
import { useParams, useNavigate } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import imgNoBook from "../assets/images/es-nocontent.svg";


function WithNavigate(props) {
  let navigate = useNavigate()
  return <ListGames {...props} navigate={navigate} params={useParams()} />
}

class ListGames extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      dataBerita: '',
      alias: window.localStorage.getItem('alias'),
      lokasi: '',
      jauh: false,
      msgJauh: '',
      lokasiMsgJauh: '',
      lat: '-7.557130246009252',
      long: '110.79448368501073',
      alt: '1',
      flagData: false,
      dataTop: [],
      hal: 1,
      mode: 1,
      search: '',
      flagNoData: false,
      flagNoBook: false,
      msgTitleNoData: 'Buku Tidak Ditemukan',
      msgNoData: 'Buku yang anda cari tidak ditemukan, silahkan cari buku lain',
      flagMoreSearch: false,
      color: window.localStorage.getItem('color'),
      darkcolor: 'bg-orang-dark',
      textcolor: 'text-orange',
      imgHead: '',
      imgSplash: '',
      firstRun: true
    }
  }

  componentDidMount = async () => {
    if (this.state.firstRun) {
      this.setState({
        alias: window.localStorage.getItem('alias'),
        firstRun: false
      })
      this.getTheme();
      this.getLocation()
    }
  }

  getTheme = async () => {
    try {
      //const json = await executeGet(TITIK_BACA_GET_THEMES + alias);
      if (window.localStorage.getItem('themes') != null) {
        const ljson = window.localStorage.getItem('themes');
        const json = JSON.parse(ljson)
        if (json.code == 200) {
          if (json.warna!="") {
            this.setState({
              color: json.warna
            });
          }
          if (json.dark!="") {
            this.setState({
              darkcolor: json.dark
            });
          }
          if (json.text!="") {
            this.setState({
              textcolor: json.text
            });
          }
          if (json.head!="") {
            this.setState({
              imgHead: json.head
            });
          }
          if (json.splash!="") {
            this.setState({
              imgSplash: json.splash
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      let long = Number(position.coords.longitude)
      let lat = Number(position.coords.latitude);
      let alt = position.coords.altitude;
      this.setState({
        lat: lat,
        long: long,
        alt: alt
      })
      this.loadData(lat, long, alt)
    })
  }
  
  loadData = async (lat, long, alt) => {

    this.getBukuPopuler(lat, long, alt)

  }

  
  getBukuPopuler = async (lat, long, alt) => {
    try {
      let hal = parseInt(this.state.hal)
      let ldata = this.state.dataTop
      let alias = window.localStorage.getItem('alias')
      let formData = new FormData()
      formData.append('latitude', lat)
      formData.append('longitude', long)
      formData.append('altitude', alt)
      formData.append('isMobile', '1')
      formData.append('hal', hal)
      //let json = await executePost(TITIK_BACA + alias, formData)
      let json = {
        "code": 200,
        "data": [
            {
                "id": "1",
                "judul": "Permainan Bahasa Inggris Sederhana",
                "penerbit": "Putar dan tebak buah apa dalam bahasa inggris",
                "penulis": "Permainan Sederhana",
                "isbn": "-",
                "eisbn": "",
                "sinopsis": "",
                "cover_file": "https:\/\/bintangpusnas.perpusnas.go.id\/images\/fruit.png",
                "qty": "50"
            },
            {
              "id": "2",
              "judul": "Permainan Matematika Sederhana",
              "penerbit": "Hitung-hitungan matematika sederhana",
              "penulis": "Perkalian, pembagian, penjumlahan, pengurangan",
              "isbn": "-",
              "eisbn": "",
              "sinopsis": "",
              "cover_file": "https:\/\/bintangpusnas.perpusnas.go.id\/images\/mathgenius.png",
              "qty": "50"
          }
        ]
      }; 
      if (json.code == 200) {
        this.setState({
          dataTop: json.data,
          alias: json.token,
          isLoading: false,
          flagData: true
        })
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: 'Network Error!' })
    }
  }

  gotoDetail = async (id) => {
    window.location.href = '/games/'+ id
  }
  gotoHome = () => {
    goBack();
    //window.location.href = window.location.origin + '/home' + '/' + this.state.alias
  }
  render() {
    return (
      <div>
        {this.state.isLoading && <SkeletonSearch />}
        <div className={"mb-16"}>
          
          <div className={"fixed top-0 w-full shadow-md mb-3 p-3 " + this.state.color }>
            <div className="flex items-center">
              <div className="flex-0 mr-4 cursor-pointer hover:bg-green rounded-full p-2">
                <svg onClick={() => this.gotoHome()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 6L9 12L15 18" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className="flex-1">
                <div className="relative text-white">
                  Permainan Edukasi
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center px-4 mt-20 line-clamp-1">
            <h3 className="text-base text-black font-semibold">{this.state.judul}</h3>
          </div>
          
          {/* {this.state.isLoading && <SkeletonSearch />} */}
          {this.state.flagData && (
            <>
                <div className="px-4">
                <Masonry
                  breakpointCols={{ default: 6, 680: 2}}
                  className="flex w-auto"
                  >

                  {this.state.dataTop.map((item, i) => {
                    return (
                      <div key={i} onClick={() => this.gotoDetail(item.id)} className="m-2 mb-4 break-inside-avoid rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                        <div className="h-[130px] overflow-hidden">
                          <LazyLoad className="w-full rounded-xl">
                          <img src={item.cover_file} alt="cover buku" className="w-full rounded-xl" />
                          </LazyLoad>
                        </div>
                        <div className="p-2">
                          <div className="title-book text-sm text-black font-semibold line-clamp-3">{item.judul}</div>
                          <div className="author mt-1 text-xs text-gray-light line-clamp-1">{item.penulis}</div>
                          <div className="publisher mt-1 text-xs text-gray-light line-clamp-1">{item.penerbit}</div>
                          
                        </div>
                      </div>
                    )}
                  )}

                </Masonry>
                  
                </div>

            </>
          )}
          
        </div>
      </div>
    )
  }
}

export default WithNavigate
