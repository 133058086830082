import { Component } from 'react'

class SkeletonRadioDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      floatMenu: false,
      width: 0,
      height: 0,
      flagPotraitPhone: false
    }
  }

  componentDidMount = async () => {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    // console.log('innerWidth = ' + window.innerWidth)
    // console.log('innerHeight = ' + window.innerHeight)
    if (window.innerWidth < 500 && window.innerHeight > 650) {
      // console.log("disini")
      this.setState({ flagPotraitPhone: true })
    } else if (window.innerWidth > 700 && window.innerHeight > 500) {
      // console.log("disini 1")
      this.setState({ flagPotraitPhone: true })
    } else {
      // console.log("disini 2")
      this.setState({ flagPotraitPhone: false })
    }
    // console.log('innerWidth = ' + this.state.width)
    // console.log('innerHeight = ' + this.state.height)
  }

  render() {
    return (
      <div>
        <div className="fixed left-[10px] top-[24px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-8">
            <path d="M15 6L9 12L15 18" stroke="#232D42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="sm:hidden">
          <div className="relative w-44 h-[200px] bg-gray-300 mx-auto after:absolute after:bottom-[-96px] after:left-0 after:w-44 after:h-44 after:bg-gray-300 after:rounded-full">
            <div className="absolute bottom-[-86px] left-[9px] w-[158px] border-4 border-gray-200 shadow-md rounded-full mx-auto z-10 overflow-hidden animate-pulse">
              <div className="rounded-full w-[150px] h-[150px] bg-gray-200 mx-auto"></div>
            </div>
          </div>
          <div className="mt-[35%] sm:mt-[18%] md:mt-[15%]">
            <div className="h-[70px] w-[70px] mb-4 rounded-full bg-gray-300 shadow-lg mx-auto animate-pulse"></div>
            <div className="w-52 h-4 bg-gray-300 rounded-full animate-pulse mx-auto mb-2"></div>
            <div className="w-32 h-4 bg-gray-300 rounded-full animate-pulse mx-auto"></div>
          </div>
          <div className="fixed bottom-[70px] p-2 w-full items-center z-50">
            <div className="grid grid-cols-4 gap-3">
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="flex w-screen">
            <div className="absolute w-[50vw] h-[100vh] bg-gray-300">
              <div className="absolute mt-[12px] ml-[2vw] w-[45vw]">
                <div className="flex items-center content-center">
                  <div className="w-[70px] h-[70px] bg-gray-200 rounded-full animate-pulse"></div>
                  <div className="ml-[20px]">
                    <div className="w-40 h-4 bg-gray-200 rounded-full animate-pulse"></div>
                    <div className="mt-2 w-32 h-3 bg-gray-200 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="absolute top-[calc(50vh-10vw)] left-[13vw] w-[20vw] shadow-md rounded-full z-10 overflow-hidden">
                  <div className="w-full h-[133px] md:h-[166px] lg:h-[204px] bg-gray-200 rounded-full mx-auto animate-pulse"></div>
                </div>
              </div>
            </div>
            <div className="absolute ml-[50vw] w-[50vw] bg-gray-200 text-center h-[100vh] p-12">
              <div className="grid grid-cols-3 gap-3 md:grid-cols-3 md:gap-4">
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Bottom Menu --> */}
        {this.state.flagPotraitPhone && (
          <div className="fixed bottom-0 w-full bg-white rounded-t-2xl py-3 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] z-50">
            <div className="grid grid-cols-5">
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center animate-pulse">
                <center>
                  <div className="flex flex-col relative">
                    <div className="w-[52px] h-[52px] bg-gray-300 rounded-full drop-shadow-md p-3 items-center absolute top-[-35px] left-[-1px]"></div>
                    <div className="w-12 h-3 mt-[29px] bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        )}
        {/* <!-- End Bottom Menu --> */}
        <div className={this.state.width > '650' && this.state.height < '500' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg block'}></div>
        <div className={this.state.width < '500' && this.state.height > '650' && 'fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg hidden'}></div>
      </div>
    )
  }
}

export default SkeletonRadioDetail
